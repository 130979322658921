<template>
  <div id="article" class="grid">
    <div class="pg-header">
      <div class="pg-back-button">
        <button class="btn-primary" @click="back">Tillbaka</button>
      </div>
      <h2 v-if="articleId < 1">Ny artikel</h2>
      <h2 v-if="articleId > 0">Editera artikel</h2>
    </div>
    <div v-if="myArticle" class="pg-form">
      <div v-if="warehousesId == 1 || warehousesId == 2" class="pg-form-group">
        <label class="pg-label">Artikelnummer</label>
        <input ref="articlenumber" v-model="myArticle.article_number" class="pg-input" type="text" />
      </div>
      <div v-if="warehousesId == 1" class="pg-form-group">
        <label class="pg-label">Artikelnummer leverantör</label>
        <input v-model="myArticle.article_number_supplier" class="pg-input" type="text" />
      </div>
      <div v-if="warehousesId == 1" class="pg-form-group">
        <label class="pg-label">Benämning</label>
        <input v-model="myArticle.article_name" class="pg-input" type="text" />
      </div>
      <div v-if="warehousesId == 1" class="pg-form-group">
        <label class="pg-label">Larmgräns</label>
        <input v-model="myArticle.alarm_limit" class="pg-input" type="number" />
      </div>
      <div v-if="warehousesId == 1" class="pg-form-group">
        <label class="pg-label">Notering</label>
        <textarea v-model="myArticle.article_description" class="pg-input"></textarea>
      </div>
      <!--   <div class="pg-form-group">
        <label class="pg-label">Lagerplats</label>
        <input v-model="myArticle.storage_location" class="pg-input" type="text" />
      </div>-->
      <div v-if="warehousesId == 1 || warehousesId == 2" class="pg-form-group">
        <label class="pg-label">Lagerplats</label>
        <select v-model="myArticle.storage_location_id" class="pg-input">
          <option
            v-for="storageLocation in myStorageLocations"
            :key="storageLocation.storage_location_id"
            :value="storageLocation.storage_location_id"
            :selected="storageLocation.storage_location_id === myArticle.storage_location_id">
            {{ storageLocation.storage_location_name }}
          </option>
        </select>
      </div>
      <div v-if="warehousesId == 1" class="pg-form-group">
        <label class="pg-label">I lager</label>
        <input v-model="myArticle.article_amount" class="pg-input" type="number" />
        <div v-if="errorMessage" class="errorMessageText">
          {{ errorMessage }}
        </div>
      </div>

      <br />
      <div class="pg-form-group">
        <button class="btn-primary" @click="saveMyArticle">Spara</button>
      </div>
      <div v-if="articleId > 0" class="pg-form-group">
        <button class="btn-danger" @click="deleteMyArticle">Radera</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { isNumberValid } from '@/services/validationHelper';

export default {
  name: 'Article',
  props: {
    articleId: {
      type: Number,
      default: 0
    },
    warehousesId: {
      type: Number,
      default: 0
    },
    originView: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      search: '',
      showError: false,
      errorMessage: '',
      myArticle: {
        article_id: 0,
        article_number: '',
        article_number_supplier: '',
        article_name: '',
        article_description: '',
        alarm_limit: 0,
        article_amount: 0,
        storage_location_id: 1,
        storage_location: '',
        warehouse_id: 0,
        warehouses_id: 0
      },
      myStorageLocations: []
    };
  },
  computed: {},
  created() {},
  async mounted() {
    await this.fetchMyStorageLocations();
    if (this.articleId > 0) {
      await this.fetchMyArticle();
    } else {
      this.$refs.articlenumber.focus();
    }
  },
  methods: {
    ...mapActions({
      fetchWarehouseArticle: 'articles/fetchWarehouseArticle',
      addArticle: 'articles/addArticle',
      updateArticle: 'articles/updateArticle',
      deleteArticle: 'articles/deleteArticle',
      fetchStorageLocations: 'storage-locations/fetchStorageLocations'
    }),
    async deleteMyArticle() {
      const result = confirm(`Är du säker att du vill radera ${this.myArticle.article_number}?`);
      if (result) {
        let response = null;
        try {
          let data = {
            article_id: this.articleId
          };
          response = await this.deleteArticle(data);
        } catch (error) {
          // console.log(error);
          // TODO : FIX ERROR LOG
        } finally {
          let addUpdateOK = false;
          if (response.success == 1) {
            addUpdateOK = true;
          }
          this.$router.replace({
            name: this.originView,
            params: {
              addUpdateOK: addUpdateOK,
              hasAddUpdate: true,
              action: 'DELETE'
            }
          });
          // console.log("We do cleanup here");
        }
      }
    },
    back: function () {
      this.$router.replace({
        name: this.originView,
        params: {
          addUpdateOK: false,
          hasAddUpdate: false,
          action: ''
        }
      });
    },
    async fetchMyArticle() {
      try {
        this.myArticle = [];
        let data = {
          article_id: this.articleId
        };
        this.myArticle = await this.fetchWarehouseArticle(data);
      } catch (error) {
        //  console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },
    async saveMyArticle() {
      if (!isNumberValid(this.myArticle.article_amount)) {
        this.errorMessage = 'Värdet måste vara numeriskt';
        return;
      }
      if (!isNumberValid(this.myArticle.alarm_limit)) {
        this.errorMessage = 'Värdet måste vara numeriskt';
        return;
      }
      let response = null;

      try {
        let data = {
          article_id: this.myArticle.article_id,
          article_number: this.myArticle.article_number,
          article_number_supplier: this.myArticle.article_number_supplier,
          article_name: this.myArticle.article_name,
          article_description: this.myArticle.article_description,
          alarm_limit: this.myArticle.alarm_limit,
          article_amount: this.myArticle.article_amount,
          storage_location_id: this.myArticle.storage_location_id,
          storage_location: this.myArticle.storage_location,
          warehouse_id: this.myArticle.warehouse_id,
          warehouses_id: this.warehousesId
        };

        if (this.articleId > 0) {
          response = await this.updateArticle(data);
        } else {
          response = await this.addArticle(data);
        }
      } catch (error) {
        // console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              //  console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        let addUpdateOK = false;

        if (response && response.success == 1) {
          addUpdateOK = true;

          this.$router.replace({
            name: this.originView,
            params: {
              addUpdateOK: addUpdateOK,
              hasAddUpdate: true,
              action: 'SAVE'
            }
          });
        }
      }
    },
    async fetchMyStorageLocations() {
      this.errorMessage = '';
      this.myStorageLocations = [];

      try {
        this.myStorageLocations = await this.fetchStorageLocations();
      } catch (error) {
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              //   console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          } else {
            this.errorMessage = 'Kunde inte hämta lagerplatser';
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  height: 100px;
}
.pg-form,
.pg-header {
  grid-column: 5/9;
}
@media (max-width: 1690px) {
  .pg-form,
  .pg-header {
    grid-column: 4/10;
  }
}
@media (max-width: 1270px) {
  .pg-form,
  .pg-header {
    grid-column: 1/13;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
</style>
