const isNumberValid = function (inputNumber) {
  const regex = /[0-9]/;
  return regex.test(inputNumber);
};

const validateEmail = function (email_address) {
  //eslint-disable-next-line
  const regex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  return regex.test(email_address);
  /*
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email_address)) {
    return false;
  } else {
    return true;
  }*/
};
module.exports = {
  isNumberValid,
  validateEmail
};
